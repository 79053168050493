import { ListDiplomas }     from 'modules/legislation/diplomas/index';
import { ViewDiplomaModal } from 'modules/legislation/diplomas/view-modal/index';
import { PLATFORM }         from 'aurelia-pal';

export class ListConsultantDiplomas extends ListDiplomas {

    listingId = 'legislation-consultant-diplomas-listing';

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.view',
        ]);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:           'resource.legislation.diploma',
            repository:         this.repository,
            edit:               null,
            destroy:            null,
            show:               {
                action: (row) => this.dialogService.open({
                    viewModel: ViewDiplomaModal,
                    model:     row,
                }),
            },
            actionsContextMenu: true,
            actions:            [
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma',
                    visible: (row) => row.viewingDocument !== null,
                    action:  (row) => this.repository.downloadFile(row.viewingDocument),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.consolidated-document',
                    visible: (row) => row.consolidatedDocument !== null,
                    action:  (row) => this.repository.downloadFile(row.consolidatedDocument),
                },
            ],
            options:            [
                {
                    label:     'form.button.export-to-excel',
                    icon:      'icon-file-excel',
                    className: 'btn bg-success',
                    action:    () => this.repository.exportListingExcel(this.filterModel),
                },
            ],
            buttons:            [],
            selectable:         true,
            sorting:            {
                column:    3,
                direction: 'desc',
            },
            columns:            [
                {
                    data:      'name',
                    name:      'diplomas.name',
                    title:     'form.field.diploma',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-name/index'),
                    nowrap:    true,
                },
                {
                    data:       'summary',
                    name:       'diplomas.summary',
                    title:      'form.field.summary',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-summary/index'),
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:       'legal_connections',
                    name:       'diplomas.summary',
                    title:      'form.field.legal-connections',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-legal-connections/index'),
                    nowrap:     true,
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:            'created_at',
                    name:            'diplomas.created_at',
                    title:           'form.field.inserted-at',
                    nowrap:          true,
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:  'status',
                    name:  'diploma_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }
}
