import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { FormSchema }                    from 'modules/legislation/consultant/applicability/analysis/perform/client-applicable-sectors/form-schema';

@inject(AppContainer, FormSchema)
export class ClientApplicableSectorsCustomElement {

    listingId = 'legislation-consultant-applicability-analysis-client-applicable-sectors-listing';

    @bindable({defaultBindingMode: bindingMode.twoWay}) client;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     */
    constructor(appContainer, formSchema) {
        this.appContainer = appContainer;
        this.formSchema   = formSchema;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.schema = this.formSchema.schema(this);
    }

}
