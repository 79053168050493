import { DiplomasRepository } from 'modules/legislation/diplomas/services/repository';

export class ApplicabilityAnalysisRepository extends DiplomasRepository {

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/consultant/diplomas/applicability/analysis/search', criteria);
    }

    /**
     * Dismisses applicability analysis of a given diploma
     *
     * @param diplomaId
     *
     * @returns {*}
     */
    dismiss(diplomaId) {
        return this.httpClient.patch(`legislation/consultant/diplomas/${diplomaId}/applicability/analysis/dismiss`);
    }

    /**
     * Performs applicability analysis of a given diploma
     *
     * @param diplomaId
     * @param data
     *
     * @returns {*}
     */
    perform(diplomaId, data) {
        return this.httpClient.post(`legislation/consultant/diplomas/${diplomaId}/applicability/analysis/perform`, data);
    }

    /**
     * Fetches consultant available clients for a given diploma
     *
     * @param diplomaId
     *
     * @returns {*}
     */
    clients(diplomaId) {
        return this.httpClient.get(`legislation/consultant/diplomas/${diplomaId}/applicability/analysis/clients`);
    }

}
