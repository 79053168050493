import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseFormViewModel }             from 'base-form-view-model';
import { PlanActionsRepository }         from 'modules/legislation/consultant/applicability/revision/perform/action-plan/services/repository';
import { FormSchema }                    from 'modules/legislation/consultant/applicability/revision/perform/action-plan/form-schema';
import { DialogController }              from 'aurelia-dialog';
import collect                           from 'collect.js';

@inject(AppContainer, PlanActionsRepository, FormSchema, DialogController)
export class ActionPlanCustomElement extends BaseFormViewModel {

    @bindable({defaultBindingMode: bindingMode.twoWay}) diplomaApplicability;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.schema = this.formSchema.schema(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:          (criteria) => this.repository.search(this.diplomaApplicability.id, criteria).then((response) => {
                    this.model.order = response.data.length ? collect(response.data).max('order') + 1 : 1;

                    return response;
                }),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            destroy:             true,
            actions:             [],
            options:             [],
            buttons:             [],
            selectable:          false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:  'order',
                    name:  'plan_actions.order',
                    title: 'form.field.order',
                },
                {
                    data:  'description',
                    name:  'plan_actions.description',
                    title: 'form.field.description',
                },
                {
                    data:  'periodicity',
                    name:  'time_intervals.name',
                    title: 'form.field.periodicity',
                },
                {
                    data:       'responsible',
                    name:       'plan_actions.responsible',
                    title:      'form.field.responsible',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:  'first_task_implementation_deadline',
                    name:  'plan_actions.first_task_implementation_deadline',
                    title: 'form.field.implementation-deadline(1st-task)',
                },
            ],
        };
    }

    /**
     * Submits form
     */
    submit() {
        this.alert = null;

        // calls repository create method
        this.repository.create(this.diplomaApplicability.id, this.model)
            .then((response) => {
                if (response.status === true) {
                    this.resetForm();

                    // creates a new notifier message
                    this.appContainer.notifier.successNotice(response.message);

                    this.datatable.instance.reload();
                } else {
                    // creates a new alert message
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch((error) => console.log(error));
    }

}
