import { inject, transient }     from 'aurelia-framework';
import { ApplicabilityAnalysis } from 'modules/legislation/consultant/applicability/analysis/perform/models/applicability-analysis';
import { UsersRepository }       from 'modules/administration/users/services/repository';

@transient()
@inject(UsersRepository)
export class FormSchema {

    currentStep = 1;

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param usersRepository
     */
    constructor(usersRepository) {
        this.usersRepository = usersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ApplicabilityAnalysis}
     */
    model() {
        let model = new ApplicabilityAnalysis();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns resume form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    resumeSchema(viewModel) {
        this.resume = {
            type:  'textarea',
            key:   'resume',
            label: 'form.field.resume',
            size:  12,
        };

        return [
            [this.resume],
        ];
    }

    /**
     * Returns clients form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    clientsSchema(viewModel) {
        this.selected_clients = {
            type:         'duallistbox',
            key:          'selected_clients',
            label:        'form.field.clients',
            required:     false,
            size:         12,
            options:      [],
            remoteSource: () => Promise.resolve(viewModel.consultantClients),
        };

        return [
            [this.selected_clients],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.consultant.applicability.analysis.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.previousStepButton = {
            type:       'button',
            label:      'form.button.previous',
            hidden:     this.currentStep <= 1,
            action:     () => this.previousStep(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-arrow-left13',
                },
            },
        };

        this.nextStepButton = {
            type:       'button',
            label:      'form.button.next',
            hidden:     this.currentStep >= 4,
            action:     () => this.nextStep(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                right:      true,
                attributes: {
                    class: 'icon-arrow-right14',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.submit',
            hidden:     this.currentStep < 5,
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-success',
            },
            icon:       {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.previousStepButton,
                this.nextStepButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Goes to previous step
     */
    previousStep() {
        this.currentStep--;

        this.evaluateButtonsVisibility();
    }

    /**
     * Goes to next step
     */
    nextStep() {
        this.currentStep++;

        this.evaluateButtonsVisibility();
    }

    /**
     * Evaluates buttons visibility
     */
    evaluateButtonsVisibility() {
        this.previousStepButton.hidden = this.currentStep <= 1;
        this.nextStepButton.hidden     = this.currentStep >= 5;
        this.submitButton.hidden       = this.currentStep < 5;
    }

    /**
     * Returns dropdown selected text
     *
     * @param field
     *
     * @returns {*|jQuery}
     */
    dropdownSelectedText(field) {
        return $('#' + field + ' option:selected').text();
    }

}
