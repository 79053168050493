import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { FormSchema }                    from 'modules/legislation/consultant/applicability/analysis/perform/applicability-types/create/form-schema';
import { DangerAlertMessage }            from 'resources/services/danger-alert-message';

@inject(AppContainer, FormSchema)
export class CreateApplicabilityTypeCustomElement {

    formId = 'administration.workflow-tasks.creation-form';

    @bindable({defaultBindingMode: bindingMode.twoWay}) applicabilityTypes;

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     */
    constructor(appContainer, formSchema) {

        this.appContainer = appContainer;
        this.formSchema   = formSchema;

    }

    /**
     * Handles created event
     */
    bind() {
        this.schema       = this.formSchema.schema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        if (isNumeric(this.model.applicability_type_id)) {
            this.pushNewRecord(this.model);
            this.resetForm();
        } else {
            this.alert = new DangerAlertMessage(
                this.appContainer.i18n.tr('validation.message.invalid-record'),
                `<li>${this.appContainer.i18n.tr('validation.message.ensure-required-fields-are-filed-in')}</li>`,
            );
        }
    }

    /**
     * Pushes new record
     *
     * @param model
     */
    pushNewRecord(model) {
        let tempModel = this.formSchema.model();

        tempModel.assign(model);

        // TODO - THINK OF A BETTER WAY TO DO THIS! MAYBE ASK BACKEND?
        tempModel.applicability_type_name = this.dropdownSelectedText('applicability_type_id');

        this.applicabilityTypes.push(tempModel);
    }

    /**
     * Returns dropdown selected text
     *
     * @param field
     *
     * @returns {*|jQuery}
     */
    dropdownSelectedText(field) {
        return $('#' + field + ' option:selected').text();
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
                this.model.assign(this.initialModel);
                resolve(true);
                reject(new Error('Error'));
            },
        );
    }

}
