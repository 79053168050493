import { inject, PLATFORM }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { ListDiplomas }                    from 'modules/legislation/diplomas/index';
import { ApplicabilityAnalysisRepository } from 'modules/legislation/consultant/applicability/analysis/services/repository';
import { DiplomaSettingsRepository }       from 'modules/legislation/management/diploma-settings/services/repository';
import { DialogService }                   from 'aurelia-dialog';
import { FilterFormSchema }                from 'modules/legislation/diplomas/filter-form-schema';
import { ViewDiplomaModal }                from 'modules/legislation/diplomas/view-modal/index';

@inject(AppContainer, ApplicabilityAnalysisRepository, DiplomaSettingsRepository, DialogService, FilterFormSchema)
export class ListConsultantDiplomas extends ListDiplomas {

    listingId = 'legislation-consultant-applicability-analysis-listing';

    headerTitle = 'listing.legislation.pending-applicability-analysis';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param diplomaSettingsRepository
     * @param dialogService
     * @param filterFormSchema
     */
    constructor(appContainer, repository, diplomaSettingsRepository, dialogService, filterFormSchema) {
        super(appContainer, repository, diplomaSettingsRepository, dialogService, filterFormSchema);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.consultant.applicability.manage',
            'legislation.consultant.applicability.view',
        ]);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            actionsContextMenu: true,
            repository:         this.repository,
            edit:               null,
            destroy:            null,
            show:               {
                action: (row) => this.dialogService.open({
                    viewModel: ViewDiplomaModal,
                    model:     row,
                }),
            },
            actions:            [
                {
                    icon:    'icon-pushpin',
                    tooltip: 'form.button.applicability',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.consultant.applicability.analysis.perform',
                        params: {
                            id: row.id,
                        },
                    }),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma',
                    visible: (row) => row.viewingDocument !== null,
                    action:  (row) => this.repository.downloadFile(row.viewingDocument),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.consolidated-document',
                    visible: (row) => row.consolidatedDocument !== null,
                    action:  (row) => this.repository.downloadFile(row.consolidatedDocument),
                },
            ],
            options:            [],
            buttons:            [],
            selectable:         true,
            sorting:            {
                column:    3,
                direction: 'desc',
            },
            columns:            [
                {
                    data:      'name',
                    name:      'diplomas.name',
                    title:     'form.field.diploma',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-name/index'),
                    nowrap:    true,
                },
                {
                    data:      'summary',
                    name:      'diplomas.summary',
                    title:     'form.field.summary',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-summary/index'),
                },
                {
                    data:      'legal_connections',
                    name:      'diplomas.summary',
                    title:     'form.field.legal-connections',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-legal-connections/index'),
                    nowrap:    true,
                },
                {
                    data:            'created_at',
                    name:            'diplomas.created_at',
                    title:           'form.field.inserted-at',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:  'status',
                    name:  'diploma_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
