import { bindable, bindingMode, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }                            from 'resources/services/app-container';

@inject(AppContainer)
export class ListApplicabilityTypesCustomElement {

    listingId = 'legislation-consultant-applicability-analysis-applicability-types-listing';

    @bindable({defaultBindingMode: bindingMode.twoWay}) readonly;
    @bindable({defaultBindingMode: bindingMode.twoWay}) applicabilityTypes;

    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:  (criteria) => Promise.resolve({
                    data:            this.applicabilityTypes,
                    recordsTotal:    this.applicabilityTypes.length,
                    recordsFiltered: this.applicabilityTypes.length,
                }),
                destroy: null,
            },
            destroySelected:     false,
            actions:             [
                {
                    icon:    'icon-trash',
                    action:  (row, $index) => this.applicabilityTypes.splice($index, 1),
                    tooltip: 'form.button.delete',
                },
            ],
            options:             [],
            buttons:             [],
            sorting:             {
                column:    3,
                direction: 'desc',
            },
            selectable:          false,
            showFilter:          false,
            showResultsPerPage:  false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            columns:             [
                {
                    data:      'applicability_type_name',
                    name:      'applicability_type_name',
                    title:     'form.field.applicability-type',
                    orderable: false,
                },
                {
                    data:      'applicability_notes',
                    name:      'applicability_notes',
                    title:     'form.field.applicability-notes',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/applicability-notes/index'),
                    orderable: false,
                },
            ],
        };
    }

}
