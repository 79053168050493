import { PLATFORM }    from 'aurelia-framework';
import { ViewDiploma } from 'modules/legislation/diplomas/view/index';

export class ViewClientDiploma extends ViewDiploma {

    backButtonRoute = 'legislation.consultant.diplomas.index';

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/legislation/diplomas/view/index.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.consultant.diplomas.manage',
            'legislation.consultant.diplomas.view',
        ]);
    }

}
