import { inject }                          from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseFormViewModel }               from 'base-form-view-model';
import { UsersRepository }                 from 'modules/administration/users/services/repository';
import { ApplicabilityAnalysisRepository } from 'modules/legislation/consultant/applicability/analysis/services/repository';
import { Client }                          from 'modules/legislation/consultant/applicability/analysis/perform/models/client';
import { ClientApplicableSector }          from 'modules/legislation/consultant/applicability/analysis/perform/models/client-applicable-sector';
import { FormSchema }                      from 'modules/legislation/consultant/applicability/analysis/perform/form-schema';

@inject(AppContainer, UsersRepository, ApplicabilityAnalysisRepository, FormSchema)
export class PerformApplicabilityAnalysis extends BaseFormViewModel {

    formId = 'legislation-consultant-applicability-analysis-perform-form';

    headerTitle = 'form.title.applicability-analysis';

    /**
     * Constructor
     *
     * @param appContainer
     * @param usersRepository
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, usersRepository, repository, formSchema) {
        super(appContainer);

        this.repository      = repository;
        this.formSchema      = formSchema;
        this.usersRepository = usersRepository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.consultant.applicability.manage',
            'legislation.consultant.applicability.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.model        = this.formSchema.model();
        this.initialModel = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.model.resume = this.diploma.resume;

            this.resumeSchema        = this.formSchema.resumeSchema(this);
            this.clientsSchema       = this.formSchema.clientsSchema(this);
            this.globalButtonsSchema = this.formSchema.globalButtonsSchema(this);
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.diploma.name;
    }

    /**
     *Submits form
     */
    submit() {
        let applicabilityAnalysisForSubmission = this.prepareApplicabilityAnalysisForSubmission();

        // calls repository create method
        return this.repository.perform(this.diploma.id, applicabilityAnalysisForSubmission)
            .then((response) => this.defaultFormSubmittedCallback(response, 'legislation.consultant.applicability.analysis.index'))
            .catch((error) => console.log(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return Promise.all([
            this.repository.find(params.id).then((response) => this.diploma = response),
            this.repository.clients(params.id).then((response) => {
                this.consultantClients = response;

                this.prepareApplicabilityAnalysis(response);
            }),
        ]);
    }

    /**
     *
     * @param consultantClients
     */
    prepareApplicabilityAnalysis(consultantClients) {
        for (let i = 0; i < consultantClients.length; i++) {
            let client = new Client();

            client.id     = consultantClients[i].id;
            client.name   = consultantClients[i].name;
            client.number = consultantClients[i].number;

            client.company_organizations_tree = consultantClients[i].company_organizations_tree;
            client.diploma_classifications    = consultantClients[i].filtered_diploma_classifications;
            client.applicability_types        = consultantClients[i].applicability_types;

            for (let j = 0; j < consultantClients[i].company_organizations.length; j++) {
                let applicableSector = new ClientApplicableSector();

                applicableSector.id      = consultantClients[i].company_organizations[j].id;
                applicableSector.name    = consultantClients[i].company_organizations[j].name;
                applicableSector.acronym = consultantClients[i].company_organizations[j].acronym;

                client.applicable_sectors[applicableSector.id] = applicableSector;
            }

            this.model.clients[client.id] = client;
        }
    }

    /**
     * Prepares applicability analysis for submission
     *
     * @returns {{}}
     */
    prepareApplicabilityAnalysisForSubmission() {
        let applicabilityAnalysis = [];

        for (let i = 0; i < this.model.selected_clients.length; i++) {
            let client = this.model.clients[this.model.selected_clients[i]];

            for (let j = 0; j < client.selected_applicable_sectors.length; j++) {
                let applicableSector = client.applicable_sectors[client.selected_applicable_sectors[j]];

                applicabilityAnalysis.push({
                    client_id:                 client.id,
                    diploma_id:                this.diploma.id,
                    applicable_sector_id:      applicableSector.id,
                    diploma_classification_id: applicableSector.analysis.diploma_classification_id,
                    applicability_type_id:     applicableSector.analysis.applicability_type_id,
                    applicability_notes:       applicableSector.analysis.applicability_notes,
                    requires_revision:         applicableSector.analysis.requires_revision,
                    plan_actions:              applicableSector.analysis.plan_actions,
                });
            }
        }

        return {
            resume:                 this.model.resume,
            applicability_analysis: applicabilityAnalysis,
        };
    }

}
