import { inject, transient }                   from 'aurelia-framework';
import { ApplicabilityRevision }               from 'modules/legislation/consultant/applicability/revision/perform/models/applicability-revision';
import { ApplicabilityTypesRepository }        from 'modules/legislation/management/applicability-types/services/repository';
import { ConcludeApplicabilityRevisionDialog } from 'modules/legislation/consultant/applicability/revision/perform/dialogs/conclude';
import { DialogService }                       from 'aurelia-dialog';

@transient()
@inject(ApplicabilityTypesRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param applicabilityTypesRepository
     * @param dialogService
     */
    constructor(applicabilityTypesRepository, dialogService) {
        this.applicabilityTypesRepository = applicabilityTypesRepository;
        this.dialogService                = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ApplicabilityRevision}
     */
    model() {
        let model = new ApplicabilityRevision();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns resume form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    reviewApplicabilitySchema(viewModel) {
        this.diploma_classification_id = {
            type:         'diploma-classifications-select',
            key:          'diploma_classification_id',
            label:        'form.field.classification',
            size:         4,
            remoteSource: () => Promise.resolve(viewModel.diplomaApplicability.client.filtered_diploma_classifications),
        };

        this.applicable_sector_id = {
            type:           'select2',
            key:            'applicable_sector_id',
            label:          'form.field.applicable-sector',
            size:           4,
            remoteSource:   () => Promise.resolve(viewModel.diplomaApplicability.client.company_organizations),
            processResults: (item) => {
                item.name = item.acronym + ' - ' + item.name;

                return item;
            },
        };

        this.applicability_type_id = {
            type:         'select2',
            key:          'applicability_type_id',
            label:        'form.field.applicability-type',
            size:         4,
            remoteSource: () => Promise.resolve(viewModel.diplomaApplicability.client.applicability_types),
        };

        this.applicability_notes = {
            type:     'textarea',
            key:      'applicability_notes',
            id:       'applicability_notes',
            label:    'form.field.applicability-notes',
            size:     12,
            required: false,
        };

        return [
            [this.diploma_classification_id, this.applicable_sector_id, this.applicability_type_id],
            [this.applicability_notes],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.consultant.applicability.revision.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.saveButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.save(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.submit',
            action:     () => {
                this.dialogService
                    .open({viewModel: ConcludeApplicabilityRevisionDialog})
                    .whenClosed((response) => {
                        if (!response.wasCancelled) {
                            viewModel.submit();
                        }
                    });
            },
            attributes: {
                class: 'btn btn-success',
            },
            icon:       {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.saveButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
