import { BaseModel } from 'modules/core/models/base-model';

export class Client extends BaseModel {

    id                          = null;
    name                        = null;
    number                      = null;
    applicability_types         = [];
    applicable_sectors          = [];
    diploma_classifications     = [];
    // ------------------------
    selected_applicable_sectors = [];

}
