import { inject }                  from 'aurelia-framework';
import { PlanAction }              from 'modules/legislation/consultant/applicability/analysis/perform/models/plan-action';
import { UsersRepository }         from 'modules/administration/users/services/repository';
import { UserGroupsRepository }    from 'modules/administration/user-groups/services/repository';
import { TimeIntervalsRepository } from 'modules/administration/time-intervals/services/repository';

@inject(UsersRepository, UserGroupsRepository, TimeIntervalsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param usersRepository
     * @param userGroupsRepository
     * @param timeIntervalsRepository
     */
    constructor(usersRepository, userGroupsRepository, timeIntervalsRepository) {
        this.usersRepository         = usersRepository;
        this.userGroupsRepository    = userGroupsRepository;
        this.timeIntervalsRepository = timeIntervalsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {PlanAction}
     */
    model() {
        let model = new PlanAction();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.order = {
            type:  'number',
            key:   'order',
            id:    'plan_action_order',
            label: 'form.field.order',
            size:  2,
        };

        this.periodicity_value = {
            type:     'number',
            key:      'periodicity_value',
            id:       'plan_action_periodicity_value',
            label:    'form.field.periodicity(value)',
            size:     2,
            required: false,
        };

        this.periodicity_id = {
            type:         'select2',
            key:          'periodicity_id',
            id:           'plan_action_periodicity_id',
            label:        'form.field.periodicity(time-unit)',
            size:         4,
            options:      [],
            remoteSource: this.timeIntervalsRepository.active.bind(this.timeIntervalsRepository),
            required:     false,
        };

        this.description = {
            type:  'textarea',
            key:   'description',
            id:    'plan_action_description',
            label: 'form.field.description',
            size:  12,
        };

        this.user_id = {
            type:         'select2',
            key:          'user_id',
            id:           'plan_action_user_id',
            label:        'form.field.user',
            size:         4,
            options:      [],
            remoteSource: this.usersRepository.active.bind(this.usersRepository),
            required:     false,
            attributes:   {
                disabled: isNumeric(viewModel.model.user_group_id),
            },
            observers:    [
                (newValue) => this.user_group_id.instance.disable(isNumeric(newValue)),
            ],
        };

        this.user_group_id = {
            type:         'select2',
            key:          'user_group_id',
            id:           'plan_action_user_group_id',
            label:        'form.field.group',
            size:         4,
            options:      [],
            remoteSource: this.userGroupsRepository.active.bind(this.userGroupsRepository),
            required:     false,
            attributes:   {
                disabled: isNumeric(viewModel.model.user_id),
            },
            observers:    [
                (newValue) => this.user_id.instance.disable(isNumeric(newValue)),
            ],
        };

        this.first_task_implementation_deadline = {
            type:     'material-ui-date-picker',
            key:      'first_task_implementation_deadline',
            id:       'plan_action_first_task_implementation_deadline',
            label:    'form.field.implementation-deadline(1st-task)',
            size:     4,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.order, this.periodicity_value, this.periodicity_id],
            [this.description],
            [this.user_id, this.user_group_id, this.first_task_implementation_deadline],
            [this.buttons],
        ];
    }

}
