import { DiplomaApplicabilitiesRepository } from 'modules/legislation/diplomas/applicability/services/repository';

export class ApplicabilityRevisionRepository extends DiplomaApplicabilitiesRepository {

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/consultant/diplomas/applicability/revision/search', criteria);
    }

    /**
     * Updates applicability revision
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/consultant/diplomas/applicability/${id}/revision`, data);
    }

    /**
     * Performs applicability revision
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    perform(id, data) {
        return this.httpClient.put(`legislation/consultant/diplomas/applicability/${id}/revision/perform`, data);
    }

}
