import { inject }                          from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseFormViewModel }               from 'base-form-view-model';
import { ApplicabilityRevisionRepository } from 'modules/legislation/consultant/applicability/revision/services/repository';
import { FormSchema }                      from 'modules/legislation/consultant/applicability/revision/perform/form-schema';

@inject(AppContainer, ApplicabilityRevisionRepository, FormSchema)
export class PerformApplicabilityRevision extends BaseFormViewModel {

    formId = 'legislation-consultant-applicability-revision-perform-form';

    headerTitle = 'form.title.applicability-revision';

    reviewApplicabilitySchema = {};
    globalButtonsSchema       = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.consultant.applicability.manage',
            'legislation.consultant.applicability.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.diplomaApplicability = response;

            this.model.assign(response);
            this.initialModel.assign(response);

            this.reviewApplicabilitySchema = this.formSchema.reviewApplicabilitySchema(this);
            this.globalButtonsSchema       = this.formSchema.globalButtonsSchema(this);
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.diplomaApplicability.diploma.name;
    }

    /**
     *Submits form
     */
    save() {
        // calls repository create method
        return this.repository.update(this.model.id, this.model)
            .then((response) => this.defaultFormSubmittedCallback(response, 'legislation.consultant.applicability.revision.index'))
            .catch((error) => console.log(error));
    }

    /**
     *Submits form
     */
    submit() {
        // calls repository create method
        return this.repository.perform(this.model.id, this.model)
            .then((response) => this.defaultFormSubmittedCallback(response, 'legislation.consultant.applicability.revision.index'))
            .catch((error) => console.log(error));
    }

}
