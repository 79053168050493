import { BaseModel } from 'modules/core/models/base-model';

export class ApplicabilityAnalysis extends BaseModel {

    resume              = null;
    clients             = [];
    // ------------------------
    applicability_types = [];
    selected_clients    = [];

}
