import { BaseModel }                      from 'modules/core/models/base-model';
import { ClientApplicableSectorAnalysis } from 'modules/legislation/consultant/applicability/analysis/perform/models/client-applicable-sector-analysis';

export class ClientApplicableSector extends BaseModel {

    id       = null;
    name     = null;
    acronym  = null;
    analysis = new ClientApplicableSectorAnalysis();

}
