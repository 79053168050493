export class FormSchema {

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.selected_applicable_sectors = {
            type:         'fancytree',
            key:          'selected_applicable_sectors',
            label:        'form.field.applicable-sectors',
            size:         12,
            options:      [],
            remoteSource: () => Promise.resolve(viewModel.client.company_organizations_tree),
        };

        return [
            [this.selected_applicable_sectors],
        ];
    }

}
