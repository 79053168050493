import { inject, PLATFORM }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { ApplicabilityRevisionRepository } from 'modules/legislation/consultant/applicability/revision/services/repository';
import { DialogService }                   from 'aurelia-dialog';
import { DiplomaSettingsRepository }       from 'modules/legislation/management/diploma-settings/services/repository';
import { ListDiplomas }                    from 'modules/legislation/diplomas/index';
import { ViewDiplomaModal }                from 'modules/legislation/diplomas/view-modal/index';
import { FilterFormSchema }                from 'modules/legislation/diplomas/filter-form-schema';

@inject(AppContainer, ApplicabilityRevisionRepository, DiplomaSettingsRepository, DialogService, FilterFormSchema)
export class ListConsultantDiplomas extends ListDiplomas {

    listingId = 'legislation-consultant-applicability-revision-listing';

    headerTitle = 'listing.legislation.pending-applicability-revision';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param diplomaSettingsRepository
     * @param dialogService
     * @param filterFormSchema
     */
    constructor(appContainer, repository, diplomaSettingsRepository, dialogService, filterFormSchema) {
        super(appContainer, repository, diplomaSettingsRepository, dialogService, filterFormSchema);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.consultant.applicability.manage',
            'legislation.consultant.applicability.view',
        ]);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            actionsContextMenu: true,
            repository:         this.repository,
            edit:               {
                visible: (row) => this.appContainer.authenticatedUser.can([
                    'legislation.consultant.applicability.manage',
                    'legislation.consultant.applicability.edit',
                ]),
                action:  (row) => this.redirectToRoute({
                    name:   'legislation.consultant.applicability.revision.perform',
                    params: {
                        id: row.id,
                    },
                }),
            },
            destroy:            null,
            show:               {
                action: (row) => this.dialogService.open({
                    viewModel: ViewDiplomaModal,
                    model:     row.diploma,
                }),
            },
            actions:            [
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma',
                    visible: (row) => row.viewingDocument !== null,
                    action:  (row) => this.repository.downloadFile(row.viewingDocument),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.consolidated-document',
                    visible: (row) => row.consolidatedDocument !== null,
                    action:  (row) => this.repository.downloadFile(row.consolidatedDocument),
                },
            ],
            options:            [],
            buttons:            [],
            selectable:         true,
            sorting:            {
                column:    6,
                direction: 'asc',
            },
            columns:            [
                {
                    data:       'diploma_flag',
                    name:       'diploma_flag',
                    title:      '',
                    type:       'embedded-image',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:   'diploma_name',
                    name:   'diplomas.name',
                    title:  'form.field.diploma',
                    nowrap: true,
                },
                {
                    data:  'client',
                    name:  'clients.name',
                    title: 'form.field.client',
                },
                {
                    data:  'applicable_sector',
                    name:  'company_organizations.name',
                    title: 'form.field.applicable-sector',
                },
                {
                    data:  'applicability_type',
                    name:  'applicability_types.name',
                    title: 'form.field.applicability-type',
                },
                {
                    data:      'applicability_notes',
                    name:      'diploma_applicabilities.applicability_notes',
                    title:     'form.field.applicability-notes',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/applicability-notes/index'),
                },
                {
                    data:            'created_at',
                    name:            'diploma_applicabilities.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:  'diploma_status',
                    name:  'diploma_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
