import { BaseModel } from 'modules/core/models/base-model';

export class ApplicabilityRevision extends BaseModel {

    diploma_classification_id = null;
    applicability_type_id     = null;
    applicability_notes       = null;
    requires_revision         = null;

}
