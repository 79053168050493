import { inject }                       from 'aurelia-framework';
import { ApplicabilityTypesRepository } from 'modules/legislation/management/applicability-types/services/repository';
import { ApplicabilityType }            from 'modules/legislation/consultant/applicability/analysis/perform/models/applicability-type';

@inject(ApplicabilityTypesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param applicabilityTypesRepository
     */
    constructor(applicabilityTypesRepository) {
        this.applicabilityTypesRepository = applicabilityTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ApplicabilityType}
     */
    model() {
        let model = new ApplicabilityType();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.applicability_type_id = {
            type:         'select2',
            key:          'applicability_type_id',
            id:           'applicability_type_id',
            label:        'form.field.applicability-type',
            size:         4,
            options:      [],
            remoteSource: this.applicabilityTypesRepository.active.bind(this.applicabilityTypesRepository),
        };

        this.applicability_notes = {
            type:     'textarea',
            key:      'applicability_notes',
            id:       'applicability_notes',
            label:    'form.field.applicability-notes',
            size:     12,
            required: false,
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.applicability_type_id],
            [this.applicability_notes],
            [this.buttons],
        ];
    }

}
