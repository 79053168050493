import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { ActionPlanListing }             from 'modules/legislation/consultant/applicability/analysis/perform/action-plan/index';
import { DialogService }                 from 'aurelia-dialog';

@inject(AppContainer, DialogService)
export class ClientApplicabilityCustomElement {

    listingId = 'legislation-consultant-applicability-analysis-client-applicability-listing';

    @bindable({defaultBindingMode: bindingMode.twoWay}) client;
    @bindable({defaultBindingMode: bindingMode.twoWay}) applicability;

    applicabilityTypeSettings = {
        hasEmptyOption: true,
        pluginOptions:  {},
    };

    diplomaClassificationSettings = {
        hasEmptyOption: true,
        pluginOptions:  {},
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     */
    constructor(appContainer, dialogService) {
        this.appContainer  = appContainer;
        this.dialogService = dialogService;
    }

    /**
     * Opens action plan listing modal
     *
     * @param applicableSector
     */
    openActionPlanListingModal(applicableSector) {
        this.dialogService.open({
            viewModel: ActionPlanListing,
            model:     applicableSector.analysis.plan_actions,
        });
    }

}
