import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { DialogController }              from 'aurelia-dialog';

@inject(AppContainer, DialogController)
export class ActionPlanListing {

    @bindable({defaultBindingMode: bindingMode.twoWay}) planActions;

    @bindable settings = {
        title:   'form.title.action-plan',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     */
    constructor(appContainer, dialogController) {
        this.appContainer     = appContainer;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(planActions) {
        this.planActions = planActions;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:  (criteria) => Promise.resolve({
                    data:            this.planActions,
                    recordsTotal:    this.planActions.length,
                    recordsFiltered: this.planActions.length,
                }),
                destroy: null,
            },
            destroySelected:     false,
            actions:             [
                {
                    icon:    'icon-trash',
                    action:  (row, $index) => this.planActions.splice($index, 1),
                    tooltip: 'form.button.delete',
                },
            ],
            options:             [],
            buttons:             [],
            selectable:          false,
            showFilter:          false,
            showResultsPerPage:  false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            sorting:             {
                column:    1,
                direction: 'asc',
            },
            columns:             [
                {
                    data:      'order',
                    name:      'order',
                    title:     'form.field.order',
                    orderable: false,
                },
                {
                    data:      'first_task_implementation_deadline',
                    name:      'first_task_implementation_deadline',
                    title:     'form.field.implementation-deadline(1st-task)',
                    orderable: false,
                },
                {
                    data:      'periodicity_name',
                    name:      'periodicity_name',
                    title:     'form.field.periodicity',
                    orderable: false,
                },
                {
                    data:       'responsible_name',
                    name:       'responsible_name',
                    title:      'form.field.responsible',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:      'description',
                    name:      'description',
                    title:     'form.field.description',
                    orderable: false,
                },
            ],
        };
    }

}
