import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class PlanActionsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param applicabilityId
     * @param criteria
     *
     * @returns {*}
     */
    search(applicabilityId, criteria) {
        return this.httpClient.post(`legislation/consultant/diplomas/applicability/${applicabilityId}/revision/plan-actions/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param applicabilityId
     * @param data
     *
     * @returns {*}
     */
    create(applicabilityId, data) {
        return this.httpClient.post(`legislation/consultant/diplomas/applicability/${applicabilityId}/revision/plan-actions`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`legislation/consultant/diplomas/applicability/revision/plan-actions/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('legislation/consultant/diplomas/applicability/revision/plan-actions/destroy-selected', {ids: ids});
    }
}
